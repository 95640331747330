<template>
  <v-row>
    <v-col cols="0" md="12" sm="12" lg="12" xl="12" class="pt-0">
      <session-title class="pt-8 px-10 pb-5" title="LGPD" />
    </v-col>
    <v-col>
      <lgpd-grid />
    </v-col>
  </v-row>
</template>

<script>
import SessionTitle from "@/components/SessionTitle";
import LgpdGrid from '../../components/LgpdGrid.vue';

export default {
  name: 'Lgpd',
  components: {
    'lgpd-grid': LgpdGrid,
    "session-title": SessionTitle,
  }
}
</script>
